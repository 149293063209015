@import '../../assets/styles/themes/css_variables.css';

/* props sobrescrita do css do calendário */
.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}
.react-datepicker__current-month {
  font-size: 17px !important;
  line-height: 19px !important;
  color: var(--color-black-primary) !important;
  font-weight: normal !important;
}
.react-datepicker__current-month::first-letter {
  text-transform: uppercase !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--background-gray-primary) !important;
  color: var(--color-black-primary) !important;
}
.react-datepicker__month-text--keyboard-selected {
  background-color: var(--background-orange-primary) !important;
  color: var(--color-black-primary) !important;
}
.react-datepicker__quarter-text--keyboard-selected {
  background-color: var(--background-orange-primary) !important;
  color: var(--color-black-primary) !important;
}

.react-datepicker__day--range-start {
  background-color: var(--background-orange-primary) !important;
  color: var(--color-black-primary) !important;
}
.react-datepicker__day--in-range{
  background-color: var(--background-orange-primary) !important;
  color: var(--color-gray-primary) !important;
}
.react-datepicker__day--range-end {
  background-color: var(--background-orange-primary) !important;
  color: var(--color-black-primary) !important;
}

.react-datepicker__day--weekend {
  color: var(--color-black-primary) !important;
  background-color: var(--color-gray-primary) !important;
}

.react-datepicker__day:hover {
  background-color: var(--background-orange-secondary) !important;
}

.react-datepicker__day--in-range.class-day-not-min-max.react-datepicker__day {
  background-color: var(--background-orange-secondary);
}
.react-datepicker__day--in-selecting-range {
  background-color: var(--background-orange-secondary) !important;
}

/* ============================ */

.week-day {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
}

.class-day-not-min-max {
  border-radius: 4px;
  background-color: var(--background-gray-primary);
  color: var(--color-gray-primary);
}

.class-day-min-max {
  background-color: var(--background-orange-primary) !important;
  border-radius: 4px;
  color: var(--color-black-primary) !important;
  font-weight: normal !important;
}

.class-day-range {
  background-color: var(--background-orange-secondary) !important;
  border-radius: 4px;
  border: 1px solid var(--background-orange-primary);
}
